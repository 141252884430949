import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './DesktopHeader.css';

interface DesktopHeaderProps {
  handleLogout: () => void;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({ handleLogout }) => {
  return (
    <header className="desktop-header">
      <Link to="/home" className="logo">Wordef</Link>
      <nav className="desktop-nav">
        <NavLink to="/home" className="nav-link">Home</NavLink>
        <NavLink to="/word-list" className="nav-link">Word List</NavLink>
        <NavLink to="/sentences" className="nav-link">Sentences</NavLink>
        <NavLink to="/progress" className="nav-link">Progress</NavLink>
      </nav>
      <button onClick={handleLogout} className="nav-link logout-button">
        Logout
      </button>
    </header>
  );
};

export default DesktopHeader;