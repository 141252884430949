import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './MobileHeader.css';

interface MobileHeaderProps {
  handleLogout: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="mobile-header">
      <Link to="/home" className="logo">Wordef</Link>
      <button className="menu-toggle" onClick={toggleMenu} aria-label={isMenuOpen ? "Close menu" : "Open menu"}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>
      {isMenuOpen && (
        <nav className="mobile-nav">
          <NavLink to="/home" className="nav-link" onClick={() => setIsMenuOpen(false)}>
            Home
          </NavLink>
          <NavLink to="/word-list" className="nav-link" onClick={() => setIsMenuOpen(false)}>
            Word List
          </NavLink>
          <NavLink to="/sentences" className="nav-link" onClick={() => setIsMenuOpen(false)}>
            Sentences
          </NavLink>
          <NavLink to="/progress" className="nav-link" onClick={() => setIsMenuOpen(false)}>
            Progress
          </NavLink>
          <button onClick={() => { handleLogout(); setIsMenuOpen(false); }} className="nav-link logout-button">
            Logout
          </button>
        </nav>
      )}
    </header>
  );
};

export default MobileHeader;