import React from 'react';
import { Word } from '../types';
import './WordOfTheDay.css';
import WordCard from './WordCard';

interface WordOfTheDayProps {
  word: Word;
}

const WordOfTheDay: React.FC<WordOfTheDayProps> = ({ word }) => {
  return (
    <div className="word-of-the-day-container">
      <h2 className="section-title">Word of the Day</h2>
      <WordCard
        word={word}
        editingWordId={null}
        onEdit={() => {}}
        onDelete={() => {}}
        onSave={async () => {}}
        isWordOfTheDay={true}
      />
    </div>
  );
};

export default WordOfTheDay;