import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';
import { QuickAddFormProps, NewWord } from '../types';
import './QuickAddForm.css';

const QuickAddForm: React.FC<QuickAddFormProps> = ({
  newWord,
  setNewWord,
  wordSuggestions,
  setWordSuggestions,
  fetchWordSuggestions,
  handleAddWord,
  setShowQuickAdd,
  generateExamples,
  examples,
  setExamples,
  exampleGenerationError,
  setExampleGenerationError,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const wordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    wordInputRef.current?.focus();
  }, []);

  const handleExampleSelect = useCallback(
    (example: string) => {
      setNewWord((prev) => ({ ...prev, example }));
    },
    [setNewWord]
  );

  const handleSuggestionSelect = useCallback(
    async (
      word: string,
      partOfSpeech: string,
      definition: string,
      pronunciation: string | null
    ) => {
      const updatedNewWord: NewWord = {
        ...newWord,
        word,
        part_of_speech: partOfSpeech,
        definition,
        example: '',
        pronunciation,
      };
      setNewWord(updatedNewWord);
      setWordSuggestions([]);
      setIsLoading(true);
      setExampleGenerationError(null);

      try {
        const generatedExamples = await generateExamples(
          word,
          partOfSpeech,
          definition
        );
        setExamples(generatedExamples);
        if (generatedExamples.length > 0) {
          setNewWord((prev) => ({
            ...prev,
            example: generatedExamples[0],
          }));
        }
      } catch (error) {
        setExampleGenerationError(
          'Failed to generate examples. Please try again.'
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      newWord,
      setNewWord,
      setWordSuggestions,
      generateExamples,
      setExamples,
      setExampleGenerationError,
    ]
  );

  const handleWordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewWord((prev) => ({ ...prev, word: value }));
    if (value.length >= 2) {
      fetchWordSuggestions(value);
    } else {
      setWordSuggestions([]);
    }
  };

  const handlePartOfSpeechChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setNewWord((prev) => ({ ...prev, part_of_speech: value }));
  };

  const handleDefinitionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewWord((prev) => ({ ...prev, definition: value }));
  };

  const handleExampleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewWord((prev) => ({ ...prev, example: value }));
  };

  return (
    <div className="quick-add-form-wrapper">
      <div className="quick-add-form">
        <div className="form-header">
          <h2>Quick Add Word</h2>
          <button
            onClick={() => setShowQuickAdd(false)}
            className="close-button"
          >
            <FaTimes />
          </button>
        </div>

        {/* Word Input Field */}
        <div className="form-group">
          <label htmlFor="word">Word</label>
          <div className="input-wrapper">
            <input
              ref={wordInputRef}
              type="text"
              id="word"
              placeholder="Enter a word"
              value={newWord.word}
              onChange={handleWordChange}
              required
            />
            {wordSuggestions.length > 0 && (
              <ul className="suggestions">
                {wordSuggestions.map((suggestion, index) => (
                  <li
                    key={`${suggestion.word}-${index}`}
                    className="suggestion-item"
                  >
                    <div className="suggestion-header">
                      <strong>{suggestion.word}</strong>
                      {suggestion.pronunciation && (
                        <span className="pronunciation">
                          {' '}
                          /{suggestion.pronunciation}/
                        </span>
                      )}
                    </div>
                    <ul className="part-of-speech-list">
                      {suggestion.definitions.map((posDef, posIndex) => (
                        <li
                          key={`${suggestion.word}-${posDef.part_of_speech}-${posIndex}`}
                          className="part-of-speech-item"
                        >
                          <strong className="part-of-speech">
                            {posDef.part_of_speech}
                          </strong>
                          :
                          <ol className="definition-list">
                            {posDef.definitions.map((def, defIndex) => (
                              <li
                                key={`${suggestion.word}-${posDef.part_of_speech}-${defIndex}`}
                                onClick={() =>
                                  handleSuggestionSelect(
                                    suggestion.word,
                                    posDef.part_of_speech,
                                    def,
                                    suggestion.pronunciation || null
                                  )
                                }
                                className="definition-item"
                              >
                                {def}
                              </li>
                            ))}
                          </ol>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Part of Speech Field */}
        <div className="form-group">
          <label htmlFor="part_of_speech">Part of Speech</label>
          <select
            id="part_of_speech"
            value={newWord.part_of_speech}
            onChange={handlePartOfSpeechChange}
            required
          >
            <option value="">Select Part of Speech</option>
            <option value="noun">Noun</option>
            <option value="verb">Verb</option>
            <option value="adjective">Adjective</option>
            <option value="adverb">Adverb</option>
          </select>
        </div>

        {/* Definition Field */}
        <div className="form-group">
          <label htmlFor="definition">Definition</label>
          <input
            type="text"
            id="definition"
            placeholder="Enter the definition"
            value={newWord.definition}
            onChange={handleDefinitionChange}
            required
          />
        </div>

        {/* Example Field */}
        <div className="form-group">
          <label htmlFor="example">Example</label>
          <input
            type="text"
            id="example"
            placeholder="Enter an example sentence"
            value={newWord.example}
            onChange={handleExampleChange}
          />
        </div>

        {/* Loading and Error Messages */}
        {isLoading && <div className="loading">Generating examples...</div>}

        {!isLoading && exampleGenerationError && (
          <div className="error-message">
            {exampleGenerationError}
            <button
              onClick={() =>
                handleSuggestionSelect(
                  newWord.word,
                  newWord.part_of_speech,
                  newWord.definition,
                  newWord.pronunciation || null
                )
              }
            >
              Retry
            </button>
          </div>
        )}

        {/* Example List */}
        {!isLoading && !exampleGenerationError && examples.length > 0 && (
          <div className="form-group">
            <label>Choose an example:</label>
            <ul className="examples-list">
              {examples.map((example, index) => (
                <li
                  key={index}
                  onClick={() => handleExampleSelect(example)}
                  className={
                    newWord.example === example ? 'selected' : ''
                  }
                >
                  {example}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Form Actions */}
        <div className="form-actions">
          <button
            type="button"
            onClick={() => setShowQuickAdd(false)}
            className="cancel-button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleAddWord}
            className="save-button"
          >
            Add Word
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickAddForm;