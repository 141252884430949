import React, { useState } from 'react';
import { FaVolumeUp, FaSpinner } from 'react-icons/fa';
import './AudioPlayer.css';

interface AudioPlayerProps {
  audioUrl: string | null | undefined;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudio = () => {
    if (audioUrl) {
      const fullUrl = `https://buildie.ru${audioUrl}`;
      console.log(`Playing audio from: ${fullUrl}`);
      const audio = new Audio(fullUrl);
      setIsPlaying(true);
      audio.play()
        .catch(error => console.error('Error playing audio:', error))
        .finally(() => setIsPlaying(false));
    }
  };

  return (
    <button onClick={playAudio} className="audio-player-button" disabled={!audioUrl || isPlaying}>
      {isPlaying ? <FaSpinner className="spinner" /> : <FaVolumeUp />}
    </button>
  );
};

export default AudioPlayer;
