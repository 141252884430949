import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, Cell, Legend } from 'recharts';
import axiosInstance from '../axiosConfig';
import './Progress.css';
import { ProgressData } from '../types';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const Progress: React.FC = () => {
  const [progressData, setProgressData] = useState<ProgressData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const processCumulativeWordCount = useCallback((data: { date: string; count: number }[]) => {
    const sortedData = data.sort((a, b) => {
      if (a.date === 'None') return -1;
      if (b.date === 'None') return 1;
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    let cumulativeCount = 0;
    const dailyData: { [key: string]: number } = {};

    sortedData.forEach((item, index) => {
      let adjustedDate = item.date;

      if (item.date === 'None') {
        adjustedDate = sortedData[index + 1]?.date || new Date().toISOString().split('T')[0];
      } else {
        adjustedDate = parseAndAdjustDate(item.date);
      }

      if (adjustedDate !== 'Invalid Date') {
        dailyData[adjustedDate] = (dailyData[adjustedDate] || 0) + item.count;
      }
    });

    return Object.entries(dailyData)
      .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
      .map(([date, count]) => {
        cumulativeCount += count;
        return { date, count: cumulativeCount };
      });
  }, []);

  const processDailyActivity = useCallback((data: { date: string; count: number }[]) => {
    const groupedData = data.reduce((acc, item) => {
      const adjustedDate = parseAndAdjustDate(item.date);
      if (adjustedDate !== 'Invalid Date') {
        acc[adjustedDate] = (acc[adjustedDate] || 0) + item.count;
      }
      return acc;
    }, {} as Record<string, number>);

    return Object.entries(groupedData)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => a.date.localeCompare(b.date));
  }, []);

  const formatXAxisDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const parseAndAdjustDate = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    date.setHours(date.getHours() - 8);
    return date.toISOString().split('T')[0];
  };

  const processSentenceAnswers = useCallback((data: { date: string; correct: number; incorrect: number }[]) => {
    return data.map(item => ({
      date: parseAndAdjustDate(item.date),
      correct: item.correct,
      incorrect: item.incorrect
    })).sort((a, b) => a.date.localeCompare(b.date));
  }, []);

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const response = await axiosInstance.get('/user/progress');
        const data = response.data;

        const processedCumulativeWordCount = processCumulativeWordCount(data.cumulative_word_count);
        const processedDailyActivity = processDailyActivity(data.daily_activity);
        const processedSentenceAnswers = processSentenceAnswers(data.sentence_answers);

        setProgressData({
          ...data,
          cumulative_word_count: processedCumulativeWordCount,
          daily_activity: processedDailyActivity,
          sentence_answers: processedSentenceAnswers,
        });
      } catch (err) {
        setError('Failed to fetch progress data. Please try again later.');
        console.error('Error fetching progress data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProgressData();
  }, [processCumulativeWordCount, processDailyActivity, processSentenceAnswers]);

  if (loading) return <div className="loading">Loading progress data...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!progressData) return null;

  return (
    <div className="progress-container">
      <div className="chart-grid">
        <section className="chart-section">
          <h3>Total Words Added</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={progressData.cumulative_word_count}>
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxisDate}
                interval="preserveStartEnd"
              />
              <YAxis />
              <Tooltip labelFormatter={formatXAxisDate} />
              <CartesianGrid strokeDasharray="3 3" />
              <Line type="monotone" dataKey="count" stroke="#0088FE" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </section>

        <section className="chart-section">
          <h3>Words Reviewed</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={progressData.daily_activity}>
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxisDate}
                interval="preserveStartEnd"
              />
              <YAxis />
              <Tooltip labelFormatter={formatXAxisDate} />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="count" fill="#00C49F" />
            </BarChart>
          </ResponsiveContainer>
        </section>

        <section className="chart-section">
          <h3>Word Breakdown by Part of Speech</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={progressData.word_breakdown}
                dataKey="count"
                nameKey="part_of_speech"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
              >
                {progressData.word_breakdown.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </section>

        <section className="chart-section">
          <h3>Word Breakdown by Difficulty Level</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={progressData.difficulty_breakdown}
            >
              <XAxis dataKey="level" />
              <YAxis />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="count" fill="#FFBB28" />
            </BarChart>
          </ResponsiveContainer>
        </section>

        <section className="chart-section">
          <h3>Sentences Answers</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={progressData.sentence_answers}>
              <XAxis dataKey="date" tickFormatter={formatXAxisDate} />
              <YAxis />
              <Tooltip
                labelFormatter={formatXAxisDate}
                formatter={(value, name) => [value, name === 'correct' ? 'Correct' : 'Incorrect']}
              />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="correct" stackId="a" fill="#2ecc71" name="Correct" />
              <Bar dataKey="incorrect" stackId="a" fill="#e74c3c" name="Incorrect" />
            </BarChart>
          </ResponsiveContainer>
        </section>
      </div>

      <section className="stats-section">
        <h3>Performance Statistics</h3>
        <div className="stats-grid">
          <div className="stat-item">
            <p className="stat-label">Review Performance</p>
            <p className="stat-value">{progressData.review_performance.toFixed(2)}%</p>
          </div>
          <div className="stat-item">
            <p className="stat-label">Current Streak</p>
            <p className="stat-value">{progressData.current_streak} days</p>
          </div>
          <div className="stat-item">
            <p className="stat-label">Longest Streak</p>
            <p className="stat-value">{progressData.longest_streak} days</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Progress;