import axios, { AxiosError } from 'axios';

interface ApiErrorResponse {
  message?: string;
}

const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://buildie.ru/wordef/api'
  : 'http://localhost:5000/api';

  export const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });
  
      if (response.data.access_token && response.data.refresh_token) {
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        localStorage.setItem('email', email);
        localStorage.setItem('tokenExpiration', String(Date.now() + 15 * 60 * 1000));
        return { user: { email }, ...response.data };
      } else {
        throw new Error('Login failed: No tokens received');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<ApiErrorResponse>;
        console.error('Login error:', axiosError.response?.data || axiosError.message);
        throw new Error(axiosError.response?.data?.message || 'Login failed');
      } else {
        console.error('Login error:', (error as Error).message);
        throw error;
      }
    }
  };

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }
  try {
    const response = await axios.post(`${API_URL}/refresh`, {}, {
      headers: { 'Authorization': `Bearer ${refreshToken}` }
    });
    if (response.data.access_token) {
      localStorage.setItem('accessToken', response.data.access_token);
      // Update the token expiration time
      localStorage.setItem('tokenExpiration', String(Date.now() + 15 * 60 * 1000));
    }
    return response.data.access_token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const logout = async () => {
  const token = localStorage.getItem('accessToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('email');
  localStorage.removeItem('tokenExpiration');
  if (token) {
    try {
      await axios.post(`${API_URL}/logout`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }
};

export const isTokenExpired = () => {
  const expiration = localStorage.getItem('tokenExpiration');
  if (!expiration) return true;
  return Date.now() > parseInt(expiration, 10);
};