import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../axiosConfig';
import './SentencesSection.css';
import ReactGA from "react-ga4";
import * as amplitude from '@amplitude/analytics-browser';
import AudioPlayer from './AudioPlayer';

interface Word {
  id: number;
  word: string;
  part_of_speech: string;
  pronunciation: string;
  definition: string;
  example: string;
  translation: string;
  audio_url: string;
}

interface SentenceCardProps {
  word: Word;
  sentences: string[];
  correctIndex: number;
  onNextWord: () => void;
}

const cardAnimationProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.3 },
};

const SentenceOption: React.FC<{
  sentence: string;
  index: number;
  selectedIndex: number | null;
  correctIndex: number;
  handleClick: (index: number) => void;
}> = ({ sentence, index, selectedIndex, correctIndex, handleClick }) => {
  const getClassName = () => {
    if (selectedIndex === null) return 'sentence-option';
    if (index === correctIndex) return 'sentence-option correct';
    if (index === selectedIndex) return 'sentence-option incorrect';
    return 'sentence-option';
  };

  return (
    <button
      className={getClassName()}
      onClick={() => handleClick(index)}
      disabled={selectedIndex !== null}
    >
      <span className="sentence-text">{sentence}</span>
    </button>
  );
};

const SentenceCard: React.FC<SentenceCardProps> = ({
  word,
  sentences,
  correctIndex,
  onNextWord,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showDefinition, setShowDefinition] = useState(false);

  const handleSentenceClick = async (index: number) => {
    if (selectedIndex !== null) return;

    setSelectedIndex(index);
    const isCorrect = index === correctIndex;

    ReactGA.event(isCorrect ? 'correct_answer' : 'wrong_answer');
    amplitude.track(isCorrect ? 'correct_answer' : 'wrong_answer');

    if (!isCorrect) {
      setShowDefinition(true);
    }

    try {
      await axiosInstance.post('/sentences/answer', {
        word: word.word,
        sentenceIndex: index,
        isCorrect,
      });
    } catch (error) {
      console.error('Error storing sentence answer:', error);
    }
  };

  return (
    <motion.div className="sentence-card" {...cardAnimationProps}>
      <div className="sentence-card__content">
        <h2 className="sentence-word">
          {word.word}
          <AudioPlayer audioUrl={word.audio_url} />
        </h2>
        <p className="sentence-details">
          <span className="part-of-speech">{word.part_of_speech}</span>
          <span className="separator"> • </span>
          <span className="pronunciation">/{word.pronunciation}/</span>
        </p>
        {showDefinition && (
          <motion.div
            className="answer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <p>
              <strong>Definition:</strong> {word.definition}
            </p>
            <p>
              <strong>Example:</strong> {word.example}
            </p>
            {word.translation && (
              <p>
                <strong>🇷🇺</strong>{' '}
                <span className="translation">{word.translation}</span>
              </p>
            )}
          </motion.div>
        )}
        <div className="sentences-container">
          {sentences.map((sentence, index) => (
            <SentenceOption
              key={index}
              sentence={sentence}
              index={index}
              selectedIndex={selectedIndex}
              correctIndex={correctIndex}
              handleClick={handleSentenceClick}
            />
          ))}
        </div>
        {selectedIndex !== null && (
          <motion.button
            className="next-word-button"
            onClick={onNextWord}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            Next Word
          </motion.button>
        )}
      </div>
    </motion.div>
  );
};

const LoadingState: React.FC = () => (
  <motion.div className="sentence-card" {...cardAnimationProps}>
    <div className="sentence-card__content">
      <div className="skeleton-loader">
        <div className="skeleton-word"></div>
        <div className="skeleton-details"></div>
        <div className="skeleton-sentences">
          <div className="skeleton-sentence"></div>
          <div className="skeleton-sentence"></div>
          <div className="skeleton-sentence"></div>
        </div>
      </div>
    </div>
  </motion.div>
);

const ErrorState: React.FC<{ error: string }> = ({ error }) => (
  <motion.div className="sentence-card" {...cardAnimationProps}>
    <div className="sentence-card__content sentence-card__content--center">
      <p>{error}</p>
    </div>
  </motion.div>
);

const NoDataState: React.FC = () => (
  <motion.div className="sentence-card" {...cardAnimationProps}>
    <div className="sentence-card__content sentence-card__content--center">
      <p>No word or sentences available.</p>
    </div>
  </motion.div>
);

const SentencesSection: React.FC = () => {
  const [word, setWord] = useState<Word | null>(null);
  const [sentences, setSentences] = useState<string[]>([]);
  const [correctIndex, setCorrectIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchRandomWordAndSentences = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const wordResponse = await axiosInstance.get('/words/random');
      const newWord: Word = wordResponse.data;
      setWord(newWord);

      const sentencesResponse = await axiosInstance.post('/generate-sentences', {
        word: newWord.word,
        part_of_speech: newWord.part_of_speech,
      });
      setSentences(sentencesResponse.data.sentences);
      setCorrectIndex(sentencesResponse.data.correctIndex);
    } catch (e) {
      console.error('Error fetching random word and sentences:', e);
      setError('Failed to load word and sentences. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRandomWordAndSentences();
  }, [fetchRandomWordAndSentences]);

  return (
    <div className="sentences-section">
      <AnimatePresence mode="wait">
        {isLoading ? (
          <LoadingState key="loading" />
        ) : error ? (
          <ErrorState key="error" error={error} />
        ) : word && sentences.length > 0 ? (
          <motion.div
            key={word.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <SentenceCard
              word={word}
              sentences={sentences}
              correctIndex={correctIndex}
              onNextWord={fetchRandomWordAndSentences}
            />
          </motion.div>
        ) : (
          <NoDataState key="no-data" />
        )}
      </AnimatePresence>
    </div>
  );
};

export default SentencesSection;
