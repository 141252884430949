import axios from 'axios';
import { refreshToken, isTokenExpired } from './auth';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production'
      ? 'https://buildie.ru/wordef/api'
      : 'http://localhost:5000/api',
  });
  
  instance.interceptors.request.use(
    async (config) => {
      if (isTokenExpired()) {
        try {
          const newToken = await refreshToken();
          config.headers['Authorization'] = `Bearer ${newToken}`;
        } catch (error) {
          // If refresh token fails, redirect to login
          window.location.href = '/login';
          return Promise.reject(error);
        }
      } else {
        const token = localStorage.getItem('accessToken');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default instance;