import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaEdit, FaTrash, FaCheck, FaTimes, FaClock, FaThermometerHalf } from 'react-icons/fa';
import { Word } from '../types';
import { DateTime } from 'luxon';
import './WordCard.css';
import AudioPlayer from './AudioPlayer';

interface WordCardProps {
  word: Word;
  editingWordId: number | null;
  onEdit: (word: Word | null) => void;
  onDelete: (id: number) => void;
  onSave: (updatedWord: Word) => Promise<void>;
  isWordOfTheDay?: boolean;
}

const WordCard: React.FC<WordCardProps> = ({
  word,
  editingWordId,
  onEdit,
  onDelete,
  onSave,
  isWordOfTheDay = false,
}) => {
  const [editedWord, setEditedWord] = useState<Word>(word);
  const [deleteConfirmId, setDeleteConfirmId] = useState<number | null>(null);

  useEffect(() => {
    setEditedWord(word);
  }, [word]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setEditedWord({ ...editedWord, [e.target.name]: e.target.value });
  };

  const handleEditClick = () => {
    onEdit(word);
  };

  const handleCancelEdit = () => {
    onEdit(null);
  };

  const handleSaveEdit = async () => {
    await onSave(editedWord);
    onEdit(null);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmId(word.id);
  };

  const handleConfirmDelete = () => {
    onDelete(word.id);
    setDeleteConfirmId(null);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmId(null);
  };

  const formatNextReview = (nextReviewDate: string | null): { text?: string; isDue: boolean } => {
    if (!nextReviewDate) return { text: 'Not scheduled', isDue: false };

    const reviewDate = DateTime.fromISO(nextReviewDate).minus({ hours: 8 });
    const now = DateTime.now();
    const diffInMinutes = reviewDate.diff(now, 'minutes').minutes;

    if (diffInMinutes < 0) {
      return { isDue: true };
    } else if (diffInMinutes < 60) {
      const roundedMinutes = Math.round(diffInMinutes);
      return {
        text: `in ${roundedMinutes} minute${roundedMinutes !== 1 ? 's' : ''}`,
        isDue: false
      };
    } else if (diffInMinutes < 1440) {
      const roundedHours = Math.round(diffInMinutes / 60);
      return {
        text: `in ${roundedHours} hour${roundedHours !== 1 ? 's' : ''}`,
        isDue: false
      };
    } else {
      const diffInDays = Math.round(diffInMinutes / 1440);
      return {
        text: `in ${diffInDays} day${diffInDays !== 1 ? 's' : ''}`,
        isDue: false
      };
    }
  };

  const getDifficultyColor = (level: string | null): string => {
    if (!level) return '#95a5a6';
    switch (level.toLowerCase()) {
      case 'easy': return '#2ecc71';
      case 'medium': return '#f39c12';
      case 'hard': return '#e74c3c';
      default: return '#95a5a6';
    }
  };

  const getCEFRColor = (level: string): string => {
    switch (level) {
      case 'A1': return '#8BC34A';
      case 'A2': return '#689F38';
      case 'B1': return '#FFC107';
      case 'B2': return '#FF9800';
      case 'C1': return '#1976D2';
      case 'C2': return '#0D47A1';
      default: return '#95a5a6';
    }
  };

  return (
    <motion.div
      className={`word-card ${isWordOfTheDay ? 'word-of-the-day' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      {editingWordId === word.id && !isWordOfTheDay ? (
        <div className="word-card__edit">
          <div className="edit-form-field">
            <label htmlFor="word">Word</label>
            <input
              type="text"
              id="word"
              name="word"
              value={editedWord.word}
              onChange={handleInputChange}
              className="edit-form-input"
            />
          </div>
          <div className="edit-form-field">
            <label htmlFor="translation">Translation</label>
            <input
              type="text"
              id="translation"
              name="translation"
              value={editedWord.translation ?? ''}
              onChange={handleInputChange}
              className="edit-form-input"
            />
          </div>
          <div className="edit-form-field">
            <label htmlFor="part_of_speech">Part of Speech</label>
            <select
              id="part_of_speech"
              name="part_of_speech"
              value={editedWord.part_of_speech}
              onChange={handleInputChange}
              className="edit-form-input"
            >
              <option value="">Select Part of Speech</option>
              <option value="noun">Noun</option>
              <option value="verb">Verb</option>
              <option value="adjective">Adjective</option>
              <option value="adverb">Adverb</option>
            </select>
          </div>
          <div className="edit-form-field">
            <label htmlFor="definition">Definition</label>
            <input
              type="text"
              id="definition"
              name="definition"
              value={editedWord.definition}
              onChange={handleInputChange}
              className="edit-form-input"
            />
          </div>
          <div className="edit-form-field">
            <label htmlFor="example">Example</label>
            <input
              type="text"
              id="example"
              name="example"
              value={editedWord.example}
              onChange={handleInputChange}
              className="edit-form-input"
            />
          </div>
          <div className="word-card__actions">
            <button onClick={handleSaveEdit} className="word-card__button word-card__button--save">
              <FaCheck />
            </button>
            <button onClick={handleCancelEdit} className="word-card__button word-card__button--cancel">
              <FaTimes />
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="word-card__header">
            <h3 className="word-card__title">
              {word.word}
              <AudioPlayer audioUrl={word.audio_url} />
            </h3>
            {word.cefr_level && (
              <span
                className="word-card__cefr-level"
                style={{ backgroundColor: getCEFRColor(word.cefr_level) }}
              >
                {word.cefr_level}
              </span>
            )}
          </div>
          <p className="word-card__pronunciation">/{word.pronunciation}/</p>
          {word.translation && (
            <p className="word-card__translation">🇷🇺 {word.translation}</p>
          )}
          <p className="word-card__pos">{word.part_of_speech}</p>
          <p className="word-card__definition">{word.definition}</p>
          <p className="word-card__example">{word.example}</p>

          <div className="word-card__footer">
            <div className="word-card__meta">
              <div className="word-card__next-review">
                <FaClock />
                {(() => {
                  const { text, isDue } = formatNextReview(word.next_review_date);
                  return (
                    <span style={{ color: isDue ? 'red' : 'inherit' }}>
                      {isDue ? 'due review' : ''}{text}
                    </span>
                  );
                })()}
              </div>
              <div className="word-card__difficulty" style={{ color: getDifficultyColor(word.difficulty_level) }}>
                <FaThermometerHalf />
                <span>{word.difficulty_level || 'Not set'}</span>
              </div>
            </div>
            {!isWordOfTheDay && (
              <div className="word-card__actions">
                <button onClick={handleEditClick} className="word-card__button word-card__button--edit">
                  <FaEdit />
                </button>
                <button onClick={handleDeleteClick} className="word-card__button word-card__button--delete">
                  <FaTrash />
                </button>
              </div>
            )}
          </div>


          {deleteConfirmId === word.id && (
            <div className="word-card__confirm">
              <p>Are you sure you want to delete this word?</p>
              <button onClick={handleConfirmDelete} className="word-card__button word-card__button--confirm">
                Yes, delete
              </button>
              <button onClick={handleCancelDelete} className="word-card__button word-card__button--cancel">
                Cancel
              </button>
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default WordCard;