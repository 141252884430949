import React, { useState, useEffect } from 'react';
import './FilterSort.css';

interface FilterSortProps {
    onFilterChange: (filters: Filters) => void;
    onSortChange: (sort: Sort) => void;
}

interface Filters {
    partOfSpeech: string;
    difficulty: string;
    level: string;
}

interface Sort {
    field: string;
    direction: 'asc' | 'desc';
}

const FilterSort: React.FC<FilterSortProps> = ({ onFilterChange, onSortChange }) => {
    const [filters, setFilters] = useState<Filters>(() => {
        const savedFilters = localStorage.getItem('wordListFilters');
        return savedFilters ? JSON.parse(savedFilters) : { partOfSpeech: '', difficulty: '', level: '' }; // Add level
    });

    const [sort, setSort] = useState<Sort>(() => {
        const savedSort = localStorage.getItem('wordListSort');
        return savedSort ? JSON.parse(savedSort) : { field: 'dateAdded', direction: 'desc' };
    });

    useEffect(() => {
        localStorage.setItem('wordListFilters', JSON.stringify(filters));
        onFilterChange(filters);
    }, [filters, onFilterChange]);

    useEffect(() => {
        localStorage.setItem('wordListSort', JSON.stringify(sort));
        onSortChange(sort);
    }, [sort, onSortChange]);

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const [field, direction] = event.target.value.split('-');
        setSort({ field, direction: direction as 'asc' | 'desc' });
    };

    return (
        <div className="filter-sort">
            <div className="filter-section">
                <label className="filter-sort-label">Filters</label>
                <select
                    name="partOfSpeech"
                    value={filters.partOfSpeech}
                    onChange={handleFilterChange}
                    className={`filter-select ${filters.partOfSpeech ? 'active-filter' : ''}`}
                >
                    <option value="">All Parts of Speech</option>
                    <option value="noun">Noun</option>
                    <option value="verb">Verb</option>
                    <option value="adjective">Adjective</option>
                    <option value="adverb">Adverb</option>
                </select>
                <select
                    name="difficulty"
                    value={filters.difficulty}
                    onChange={handleFilterChange}
                    className={`filter-select ${filters.difficulty ? 'active-filter' : ''}`}
                >
                    <option value="">All Difficulties</option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                </select>
                <select
                    name="level"
                    value={filters.level}
                    onChange={handleFilterChange}
                    className={`filter-select ${filters.level ? 'active-filter' : ''}`}
                >
                    <option value="">All Levels</option>
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                    <option value="C1">C1</option>
                    <option value="C2">C2</option>
                </select>
            </div>
            <div className="sort-section">
                <label className="filter-sort-label">Sorting</label>
                <select
                    value={`${sort.field}-${sort.direction}`}
                    onChange={handleSortChange}
                    className="sort-select"
                >
                    <option value="dateAdded-desc">Newest First</option>
                    <option value="dateAdded-asc">Oldest First</option>
                    <option value="nextReview-desc">Review Date (Latest First)</option>
                    <option value="nextReview-asc">Review Date (Earliest First)</option>
                    <option value="word-asc">Word A-Z</option>
                    <option value="word-desc">Word Z-A</option>
                    <option value="level-asc">Level (A1 to C2)</option>
                    <option value="level-desc">Level (C2 to A1)</option>
                </select>
            </div>
        </div>
    );
};

export default FilterSort;