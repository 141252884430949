import React from 'react';
import { Link } from 'react-router-dom';
import { FaTrophy, FaCalendarCheck } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { NoWordsToReviewProps } from '../types';
import './NoWordsToReview.css';

const NoWordsToReview: React.FC<NoWordsToReviewProps> = ({ totalWords, streakDays }) => {
  return (
    <div className="home">
      <motion.div
        className="no-words-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="engagement-header">Ready to expand your vocabulary?</h2>
        <p className="action-subheader">No words to review right now. Let's add some new ones!</p>
        <div className="stats-container">
          <Link to="/word-list">
            <motion.div
              className="stat-item"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaTrophy className="stat-icon trophy" />
              <p className="stat-label">Total Words</p>
              <p className="stat-value">{totalWords}</p>
            </motion.div>
          </Link>
          <Link to="/progress">
            <motion.div
              className="stat-item"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaCalendarCheck className="stat-icon calendar" />
              <p className="stat-label">Day Streak</p>
              <p className="stat-value">{streakDays}</p>
            </motion.div>
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default NoWordsToReview;