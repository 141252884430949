import React, { useState, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Word } from '../types';
import FilterSort from './FilterSort';
import WordCard from './WordCard';
import './WordList.css';

interface WordListProps {
  words: Word[];
  onEdit: (word: Word | null) => void;
  onDelete: (id: number) => Promise<void>;
  onSave: (updatedWord: Word) => Promise<void>;
  editingWordId: number | null;
}

const WordList: React.FC<WordListProps> = ({ words, onEdit, onDelete, onSave, editingWordId }) => {
  const [filters, setFilters] = useState({ partOfSpeech: '', difficulty: '', level: '' });
  const [sort, setSort] = useState({ field: 'dateAdded', direction: 'desc' as 'asc' | 'desc' });

  const filteredAndSortedWords = useMemo(() => {
    return words
      .filter(word =>
        (filters.partOfSpeech === '' || word.part_of_speech === filters.partOfSpeech) &&
        (filters.difficulty === '' || word.difficulty_level === filters.difficulty) &&
        (filters.level === '' || word.cefr_level === filters.level)
      )
      .sort((a, b) => {
        if (sort.field === 'word') {
          return sort.direction === 'asc' ? a.word.localeCompare(b.word) : b.word.localeCompare(a.word);
        } else if (sort.field === 'nextReview') {
          const dateA = a.next_review_date ? new Date(a.next_review_date) : new Date(0);
          const dateB = b.next_review_date ? new Date(b.next_review_date) : new Date(0);
          return sort.direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        } else if (sort.field === 'dateAdded') {
          const dateA = a.date_added ? new Date(a.date_added) : new Date(0);
          const dateB = b.date_added ? new Date(b.date_added) : new Date(0);
          return sort.direction === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        } else if (sort.field === 'level') {
          const levelOrder = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
          const indexA = levelOrder.indexOf(a.cefr_level || '');
          const indexB = levelOrder.indexOf(b.cefr_level || '');
          return sort.direction === 'asc' ? indexA - indexB : indexB - indexA;
        }
        return 0;
      });
  }, [words, filters, sort]);

  return (
    <div className="word-list-container">
      <FilterSort
        onFilterChange={setFilters}
        onSortChange={setSort}
      />
      <div className="word-list">
        <AnimatePresence>
          {filteredAndSortedWords.map((word) => (
            <WordCard
              key={word.id}
              word={word}
              editingWordId={editingWordId}
              onEdit={onEdit}
              onDelete={onDelete}
              onSave={onSave}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default WordList;