import React, { useState, useEffect } from 'react';

interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  children: React.ReactNode;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({ onRefresh, children }) => {
  const [startY, setStartY] = useState(0);
  const [pulling, setPulling] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (refreshing) {
      onRefresh().then(() => setRefreshing(false));
    }
  }, [refreshing, onRefresh]);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchY = e.touches[0].clientY;
    setStartY(touchY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchY = e.touches[0].clientY;
    const pullDistance = touchY - startY;

    if (pullDistance > 0 && window.scrollY === 0) {
      setPulling(true);
      e.preventDefault();
    } else {
      setPulling(false);
    }
  };

  const handleTouchEnd = () => {
    if (pulling) {
      setRefreshing(true);
    }
    setPulling(false);
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};

export default PullToRefresh;