import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Word } from '../types';
import './ReviewCard.css';
import AudioPlayer from './AudioPlayer';

interface ReviewCardProps {
  currentReviewWord: Word | null;
  showAnswer: boolean;
  toggleShowAnswer: () => void;
  handleReview: (difficulty: 'hard' | 'medium' | 'easy') => void;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  currentReviewWord,
  showAnswer,
  toggleShowAnswer,
  handleReview
}) => {
  return (
    <div className="review-card">
      <h2 className="review-word">
        {currentReviewWord?.word}
        <AudioPlayer audioUrl={currentReviewWord?.audio_url} />
      </h2>
      <p className="review-details">
        <span className="part-of-speech">{currentReviewWord?.part_of_speech}</span>
        <span className="separator"> • </span>
        {currentReviewWord?.pronunciation && (
          <span className="pronunciation">/{currentReviewWord.pronunciation}/</span>
        )}
      </p>

      <button onClick={toggleShowAnswer} className="toggle-answer-btn">
        {showAnswer ? <FaEyeSlash /> : <FaEye />}
        <span>{showAnswer ? 'Hide Answer' : 'Show Answer'}</span>
      </button>
      {showAnswer && (
        <div className="answer">
		  {currentReviewWord?.translation && (
            <p><strong>🇷🇺</strong> <span className="translation">{currentReviewWord.translation}</span></p>
          )}
          <p><strong>Definition:</strong> {currentReviewWord?.definition}</p>
          <p><strong>Example:</strong> {currentReviewWord?.example}</p>
        </div>
      )}
      <div className="review-buttons">
        <button onClick={() => handleReview('hard')} className="btn-hard">Hard</button>
        <button onClick={() => handleReview('medium')} className="btn-medium">Medium</button>
        <button onClick={() => handleReview('easy')} className="btn-easy">Easy</button>
      </div>
      <div className="review-instructions">
        <p className="review-instruction">
          Rate how well you remembered this word:
        </p>
        <ul className="review-explanation">
          <li><strong>Hard:</strong> You had difficulty remembering or got it wrong. You'll review this word again soon.</li>
          <li><strong>Medium:</strong> You remembered, but it took some effort. You'll see this word again after a moderate interval.</li>
          <li><strong>Easy:</strong> You recalled the word quickly and correctly. You'll see this word again after a longer interval.</li>
        </ul>
      </div>
    </div>
  );
};

export default ReviewCard;